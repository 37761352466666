@font-face {
    font-family: 'EB Garamond';
    src:
        url('EBGaramond-ExtraBold.woff2') format('woff2'),
        url('EBGaramond-ExtraBold.woff') format('woff'),
        url('EBGaramond-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'EB Garamond';
    src:
        url('EBGaramond-BoldItalic.woff2') format('woff2'),
        url('EBGaramond-BoldItalic.woff') format('woff'),
        url('EBGaramond-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'EB Garamond';
    src:
        url('EBGaramond-Bold.woff2') format('woff2'),
        url('EBGaramond-Bold.woff') format('woff'),
        url('EBGaramond-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'EB Garamond';
    src:
        url('EBGaramond-SemiBold.woff2') format('woff2'),
        url('EBGaramond-SemiBold.woff') format('woff'),
        url('EBGaramond-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'EB Garamond';
    src:
        url('EBGaramond-Regular.woff2') format('woff2'),
        url('EBGaramond-Regular.woff') format('woff'),
        url('EBGaramond-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'EB Garamond';
    src:
        url('EBGaramond-SemiBoldItalic.woff2') format('woff2'),
        url('EBGaramond-SemiBoldItalic.woff') format('woff'),
        url('EBGaramond-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'EB Garamond';
    src:
        url('EBGaramond-Italic.woff2') format('woff2'),
        url('EBGaramond-Italic.woff') format('woff'),
        url('EBGaramond-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'EB Garamond';
    src:
        url('EBGaramond-Italic_1.woff2') format('woff2'),
        url('EBGaramond-Italic_1.woff') format('woff'),
        url('EBGaramond-Italic_1.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'EB Garamond';
    src:
        url('EBGaramond-ExtraBoldItalic.woff2') format('woff2'),
        url('EBGaramond-ExtraBoldItalic.woff') format('woff'),
        url('EBGaramond-ExtraBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'EB Garamond';
    src:
        url('EBGaramond-MediumItalic.woff2') format('woff2'),
        url('EBGaramond-MediumItalic.woff') format('woff'),
        url('EBGaramond-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'EB Garamond';
    src:
        url('EBGaramond-Medium.woff2') format('woff2'),
        url('EBGaramond-Medium.woff') format('woff'),
        url('EBGaramond-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'EB Garamond';
    src:
        url('EBGaramond-Regular_1.woff2') format('woff2'),
        url('EBGaramond-Regular_1.woff') format('woff'),
        url('EBGaramond-Regular_1.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
