@font-face {
    font-family: 'Open Sans';
    src:
        url('OpenSans-BoldItalic.woff2') format('woff2'),
        url('OpenSans-BoldItalic.woff') format('woff'),
        url('OpenSans-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src:
        url('OpenSans-Bold.woff2') format('woff2'),
        url('OpenSans-Bold.woff') format('woff'),
        url('OpenSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src:
        url('OpenSans-ExtraBold.woff2') format('woff2'),
        url('OpenSans-ExtraBold.woff') format('woff'),
        url('OpenSans-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src:
        url('OpenSans-Light.woff2') format('woff2'),
        url('OpenSans-Light.woff') format('woff'),
        url('OpenSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src:
        url('OpenSans-Regular.woff2') format('woff2'),
        url('OpenSans-Regular.woff') format('woff'),
        url('OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src:
        url('OpenSans-LightItalic.woff2') format('woff2'),
        url('OpenSans-LightItalic.woff') format('woff'),
        url('OpenSans-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src:
        url('OpenSans-SemiBoldItalic.woff2') format('woff2'),
        url('OpenSans-SemiBoldItalic.woff') format('woff'),
        url('OpenSans-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src:
        url('OpenSans-Italic.woff2') format('woff2'),
        url('OpenSans-Italic.woff') format('woff'),
        url('OpenSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src:
        url('OpenSans-Italic_1.woff2') format('woff2'),
        url('OpenSans-Italic_1.woff') format('woff'),
        url('OpenSans-Italic_1.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src:
        url('OpenSans-SemiBold.woff2') format('woff2'),
        url('OpenSans-SemiBold.woff') format('woff'),
        url('OpenSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
