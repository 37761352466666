@font-face {
    font-family: 'Mulish';
    src:
        url('Mulish-ExtraLight.woff2') format('woff2'),
        url('Mulish-ExtraLight.woff') format('woff'),
        url('Mulish-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src:
        url('Mulish-Medium.woff2') format('woff2'),
        url('Mulish-Medium.woff') format('woff'),
        url('Mulish-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src:
        url('Mulish-BlackItalic.woff2') format('woff2'),
        url('Mulish-BlackItalic.woff') format('woff'),
        url('Mulish-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src:
        url('Mulish-ExtraBoldItalic.woff2') format('woff2'),
        url('Mulish-ExtraBoldItalic.woff') format('woff'),
        url('Mulish-ExtraBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src:
        url('Mulish-BoldItalic.woff2') format('woff2'),
        url('Mulish-BoldItalic.woff') format('woff'),
        url('Mulish-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src:
        url('Mulish-LightItalic.woff2') format('woff2'),
        url('Mulish-LightItalic.woff') format('woff'),
        url('Mulish-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src:
        url('Mulish-MediumItalic.woff2') format('woff2'),
        url('Mulish-MediumItalic.woff') format('woff'),
        url('Mulish-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src:
        url('Mulish-Regular.woff2') format('woff2'),
        url('Mulish-Regular.woff') format('woff'),
        url('Mulish-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src:
        url('Mulish-Regular_1.woff2') format('woff2'),
        url('Mulish-Regular_1.woff') format('woff'),
        url('Mulish-Regular_1.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src:
        url('Mulish-Italic.woff2') format('woff2'),
        url('Mulish-Italic.woff') format('woff'),
        url('Mulish-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src:
        url('Mulish-Italic_1.woff2') format('woff2'),
        url('Mulish-Italic_1.woff') format('woff'),
        url('Mulish-Italic_1.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src:
        url('Mulish-SemiBoldItalic.woff2') format('woff2'),
        url('Mulish-SemiBoldItalic.woff') format('woff'),
        url('Mulish-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src:
        url('Mulish-Light.woff2') format('woff2'),
        url('Mulish-Light.woff') format('woff'),
        url('Mulish-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src:
        url('Mulish-ExtraLightItalic.woff2') format('woff2'),
        url('Mulish-ExtraLightItalic.woff') format('woff'),
        url('Mulish-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src:
        url('Mulish-ExtraBold.woff2') format('woff2'),
        url('Mulish-ExtraBold.woff') format('woff'),
        url('Mulish-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src:
        url('Mulish-Bold.woff2') format('woff2'),
        url('Mulish-Bold.woff') format('woff'),
        url('Mulish-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src:
        url('Mulish-Black.woff2') format('woff2'),
        url('Mulish-Black.woff') format('woff'),
        url('Mulish-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mulish';
    src:
        url('Mulish-SemiBold.woff2') format('woff2'),
        url('Mulish-SemiBold.woff') format('woff'),
        url('Mulish-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
