@font-face {
    font-family: 'Arial';
    src:
        url('ArialTh.woff2') format('woff2'),
        url('ArialTh.woff') format('woff'),
        url('ArialTh.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arial';
    src:
        url('ArialNarrow.woff2') format('woff2'),
        url('ArialNarrow.woff') format('woff'),
        url('ArialNarrow.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Arial';
    src:
        url('ArialMTBlack.woff2') format('woff2'),
        url('ArialMTBlack.woff') format('woff'),
        url('ArialMTBlack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
