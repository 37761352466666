@font-face {
    font-family: 'Times New Roman';
    src:
        url('TimesNRCyrMT.woff2') format('woff2'),
        url('TimesNRCyrMT.woff') format('woff'),
        url('TimesNRCyrMT.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Times New Roman';
    src:
        url('TimesNewRomanPS-ItalicMT.woff2') format('woff2'),
        url('TimesNewRomanPS-ItalicMT.woff') format('woff'),
        url('TimesNewRomanPS-ItalicMT.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Times New Roman';
    src:
        url('TimesNRCyrMT-Bold.woff2') format('woff2'),
        url('TimesNRCyrMT-Bold.woff') format('woff'),
        url('TimesNRCyrMT-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Times New Roman';
    src:
        url('Times-BoldItalic.woff2') format('woff2'),
        url('Times-BoldItalic.woff') format('woff'),
        url('Times-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}
