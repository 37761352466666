$picker-radius: 16px;
$border-radius: 10px;

body {
    .react-colorful {
        width: 100%;
        border-radius: $border-radius;

        &__pointer {
            width: $picker-radius;
            height: $picker-radius;
            border: 2px solid white;
            border-radius: 50%;
        }

        &__saturation {
            border-radius: $border-radius;
        }

        &__hue {
            height: 18px;
            border-radius: $border-radius;
            margin-top: 15px;
        }
    }
}
