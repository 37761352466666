@font-face {
    font-family: 'Inter';
    src:
        url('Inter-Bold.woff2') format('woff2'),
        url('Inter-Bold.woff') format('woff'),
        url('Inter-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src:
        url('Inter-Black.woff2') format('woff2'),
        url('Inter-Black.woff') format('woff'),
        url('Inter-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src:
        url('Inter-Thin.woff2') format('woff2'),
        url('Inter-Thin.woff') format('woff'),
        url('Inter-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src:
        url('Inter-SemiBold.woff2') format('woff2'),
        url('Inter-SemiBold.woff') format('woff'),
        url('Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src:
        url('Inter-Light.woff2') format('woff2'),
        url('Inter-Light.woff') format('woff'),
        url('Inter-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src:
        url('Inter-ExtraLight.woff2') format('woff2'),
        url('Inter-ExtraLight.woff') format('woff'),
        url('Inter-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src:
        url('Inter-Regular.woff2') format('woff2'),
        url('Inter-Regular.woff') format('woff'),
        url('Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src:
        url('Inter-Medium.woff2') format('woff2'),
        url('Inter-Medium.woff') format('woff'),
        url('Inter-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src:
        url('Inter-ExtraBold.woff2') format('woff2'),
        url('Inter-ExtraBold.woff') format('woff'),
        url('Inter-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
