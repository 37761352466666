@font-face {
    font-family: 'Quicksand';
    src:
        url('Quicksand-Bold.woff2') format('woff2'),
        url('Quicksand-Bold.woff') format('woff'),
        url('Quicksand-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Quicksand';
    src:
        url('Quicksand-Light.woff2') format('woff2'),
        url('Quicksand-Light.woff') format('woff'),
        url('Quicksand-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Quicksand';
    src:
        url('Quicksand-SemiBold.woff2') format('woff2'),
        url('Quicksand-SemiBold.woff') format('woff'),
        url('Quicksand-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Quicksand';
    src:
        url('Quicksand-Medium.woff2') format('woff2'),
        url('Quicksand-Medium.woff') format('woff'),
        url('Quicksand-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Quicksand';
    src:
        url('Quicksand-Regular.woff2') format('woff2'),
        url('Quicksand-Regular.woff') format('woff'),
        url('Quicksand-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
