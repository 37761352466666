@font-face {
    font-family: 'Anuphan';
    src:
        url('Anuphan-ExtraLight.woff2') format('woff2'),
        url('Anuphan-ExtraLight.woff') format('woff'),
        url('Anuphan-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Anuphan';
    src:
        url('Anuphan-Bold.woff2') format('woff2'),
        url('Anuphan-Bold.woff') format('woff'),
        url('Anuphan-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Anuphan';
    src:
        url('Anuphan-Light.woff2') format('woff2'),
        url('Anuphan-Light.woff') format('woff'),
        url('Anuphan-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Anuphan';
    src:
        url('Anuphan-Medium.woff2') format('woff2'),
        url('Anuphan-Medium.woff') format('woff'),
        url('Anuphan-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Anuphan';
    src:
        url('Anuphan-SemiBold.woff2') format('woff2'),
        url('Anuphan-SemiBold.woff') format('woff'),
        url('Anuphan-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Anuphan';
    src:
        url('Anuphan-Thin.woff2') format('woff2'),
        url('Anuphan-Thin.woff') format('woff'),
        url('Anuphan-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Anuphan';
    src:
        url('Anuphan-Regular.woff2') format('woff2'),
        url('Anuphan-Regular.woff') format('woff'),
        url('Anuphan-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
