@font-face {
    font-family: 'Crimson Text';
    src:
        url('CrimsonText-SemiBold.woff2') format('woff2'),
        url('CrimsonText-SemiBold.woff') format('woff'),
        url('CrimsonText-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Crimson Text';
    src:
        url('CrimsonText-BoldItalic.woff2') format('woff2'),
        url('CrimsonText-BoldItalic.woff') format('woff'),
        url('CrimsonText-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Crimson Text';
    src:
        url('CrimsonText-Italic.woff2') format('woff2'),
        url('CrimsonText-Italic.woff') format('woff'),
        url('CrimsonText-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Crimson Text';
    src:
        url('CrimsonText-Bold.woff2') format('woff2'),
        url('CrimsonText-Bold.woff') format('woff'),
        url('CrimsonText-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Crimson Text';
    src:
        url('CrimsonText-Regular.woff2') format('woff2'),
        url('CrimsonText-Regular.woff') format('woff'),
        url('CrimsonText-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Crimson Text';
    src:
        url('CrimsonText-SemiBoldItalic.woff2') format('woff2'),
        url('CrimsonText-SemiBoldItalic.woff') format('woff'),
        url('CrimsonText-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}
