.react-select__indicator-separator {
    display: none;
}

.react-select__option {
    padding: 6px 16px;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    &:hover {
        background-color: #f3f3f3;
    }
}

.react-select__control {
    border: 1px solid #d0d3d9;
    border-radius: 8px !important;
    min-height: 48px !important;
    cursor: pointer !important;

    &:hover {
        // TODO: color
        border-color: #0014cb !important;
    }

    &--is-focused {
        // TODO: color
        border-color: #0014cb !important;
        box-shadow: none !important;
    }
}

.react-select__menu-list {
    max-height: 160px;
}

.react-select__value-container {
    font-size: 14px;
    padding: 0 !important;
    padding-left: 16px !important;
}

.react-select__single-value {
    font-size: 16px;
    grid-area: 1/1/2/3;
    white-space: nowrap;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 8px;
}

.react-select__indicator {
    padding-right: 14px !important;
}

.react-select__input-container {
    margin: 0 !important;
}

.react-select__menu {
    z-index: 9999 !important;
}

.react-select__container {
    width: 100%;
}
